// src/pages/DoctorDetails.jsx
import React from "react";
import "../Doctors/DoctorsDetail.css";
import doctorsData from "../Data/doctorsData";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const DoctorDetails = () => {
  const { doctorId } = useParams();
  const navigate = useNavigate();
  const doctor = doctorsData[doctorId];
  // console.log(doctor)
  if (!doctor) {
    return (
      <div className="container text-center mb-2">
        <h1 className="mt-5">Doctor's Data Not Found</h1>
        <p>The doctor information you are trying to view is not available.</p>
        {/* <Link to="/Departments">Back to Departments</Link> */}
        <button className="btn btn-success" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="container doctor-profile mb-2">
        <div className="card profile-section">
          <div className="row doc">
            <div className="col-md-3 text-center">
              <img
                src={doctor.image}
                alt={doctor.name}
                className="img-fluid rounded-circle profile-img"
                title={doctor.name}
              />
            </div>
            <div className="col-md-9">
              <div>
                <h2>{doctor.name}</h2>
                <h4 className="fs-6">{doctor.qualification}</h4>
                <hr />
                <h5>{doctor.position}</h5>
                <h6 className="fs-6">{doctor.department}</h6>
              </div>
              <div className="mt-2">
                {/* <button className="btn btn-danger me-2">Book Appointment(Coming Soon)</button> */}
                <button
                  className="btn btn-success"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 about-sections">
          <h3 className="fs-5">About</h3>
          <ul className="info-sec">
            {doctor.about.map((item, index) => (
              <li className="info-sec" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div className="card mt-3 education-section">
          <h3 className="fs-5">Education</h3>
          <ul className="info-sec">
            {doctor.education.map((item, index) => (
              <li className="info-sec" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        {doctor.experience && doctor.experience.length > 0 && (
        <div className="card mt-3 experience-section">
          <h3 className="fs-5">Experience</h3>
          <ul className="info-sec">
            {doctor.experience.map((item, index) => (
              <li className="info-sec" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
        )}
        {doctor.memberships && doctor.memberships.length > 0 && (
          <div className="card mt-3 memberships-section">
            <h3 className="fs-5">Memberships</h3>
            <ul className="info-sec">
              {doctor.memberships.map((membership, index) => (
                <li className="info-sec" key={index}>
                  {membership}
                </li>
              ))}
            </ul>
          </div>
        )}
        {doctor.specialization && doctor.specialization.length > 0 && (
          <div className="card mt-3 specialization-section">
            <h3 className="fs-5">Specialization</h3>
            <ul className="info-sec">
              {doctor.specialization.map((spec, index) => (
                <li className="info-sec" key={index}>
                  {spec}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default DoctorDetails;
