import React, { useEffect } from "react";
import "./Academic.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import AcademicBanner from "../../assets/about_banner.jpg";
import CourseBg from "../../assets/shapes/missionvision_bg.png";
import CourseBg1 from "../../assets/shapes/hexagontop3D.png";

function Academics() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            {/* Academic Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={AcademicBanner} alt="AcademicBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>Academics</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Academic Sections */}
            <section className="mt-5 mt-lg-3">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="academic-sidebar">
                                <div className="sidebar-widget">
                                    <div className="academic-title">
                                        <h3>Academic Courses Offered</h3>
                                        <p className="text-title ">Elevate Your Medical Career with Ashwini Group of Hospitals</p>
                                        <p className="mt-4">Ashwini Group of Hospitals is committed to excellence in healthcare and medical education. We offer advanced academic programs designed to nurture the next generation of medical professionals. Join us in our pursuit of healthcare excellence through our distinguished training programs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="card mb-3 academic-content">
                                <div className="row g-0">
                                    <div className="card-body academic-block">
                                        <h3>Admission Procedure</h3>
                                        <p>A common entrance test (NEET ) is conducted by NBE. Admission for broad specialty is done in January Session and for Super specialty is done in July session. Please contact us for more information:</p>

                                        <p><span className="text-title text-decoration-underline">Ashwini Group of Hospitals</span></p>
                                        <p className="lh-1"><span className="text-title">Mail</span> : academics@ashwinihospitals.in</p>
                                        <p className="lh-1"><span className="text-title">Phone</span> : 8093012752</p>
                                        <p className="lh-1"><span className="text-title">Website</span> :
                                            <a href='https://www.ashwinihospitalcuttack.com/courses/' target="_blank" rel="noopener noreferrer">
                                                www.ashwinihospitalcuttack.com/courses</a>
                                        </p>
                                        <p className="lh-1"><span className="text-title">NBE</span> :
                                            <a href='https://nbe.edu.in' target="_blank" rel="noopener noreferrer">
                                                https://nbe.edu.in</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* Courses */}
            <section className="course-section py-4">
                <div className="container">
                    <div className="image-shape">
                        <div className="shape-1">
                            <img src={CourseBg1} alt="BgDots" />
                        </div>
                    </div>
                    <div className="sec-title mb-4">
                        <h1>Courses</h1>
                    </div>

                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="course-block-one fadeInUp h-100">
                                <div className="course-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h3>DNB Courses</h3>
                                        <h5>3 Years</h5>
                                    </div>
                                    <div className="table-content">
                                        <p>Accredited by the National Board of Examinations, we provide top-notch Post Graduate training since 2021. Boasting 400 beds across three hospitals, well-equipped departments, and a modern library, we ensure a high case load and up-to-date resources.</p>
                                    </div>
                                    <div className="table-lists">
                                        <ul>
                                            <li>Respiratory Medicine (Post MBBS & Post Diploma)</li>
                                            <li>Emergency Medicine (Post MBBS)</li>
                                            <li>Orthopedics (Post MBBS & Post Diploma)</li>
                                        </ul>
                                    </div>
                                    <div className="text-center mt-auto">
                                        <a href="/AcademicDetails/DNB" className="theme-btn btn-one">
                                            <span>Know More</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="course-block-one fadeInUp h-100">
                                <div className="course-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h3>DrNB Courses</h3>
                                        <h5>3 Years</h5>
                                    </div>
                                    <div className="table-content">
                                        <p>Accredited by the National Board of Examinations in Medical Sciences, New Delhi, we proudly offer Post Graduate training to DrNB Trainees in various fields since 2023.</p>
                                    </div>
                                    <div className="table-lists">
                                        <ul>
                                            <li>Neurology (Post PG)</li>
                                            <li>Neurosurgery (Post PG)</li>
                                            <li>Critical Care (Post PG) <span class="badge bg-danger">New</span></li>
                                            <li>Plastic Surgery (Post PG)</li>
                                        </ul>
                                    </div>
                                    <div className="text-center mt-auto">
                                        <a href="/AcademicDetails/DrNB" className="theme-btn btn-one">
                                            <span>Know More</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="course-block-one fadeInUp h-100">
                                <div className="course-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h3>Post-Doctor Fellowship Programs</h3>
                                        <h5>3 Months</h5>
                                    </div>
                                    <div className="table-content">
                                        <p>Advance your Medical Expertise with our Specialized Fellowship Programs designed to enhance Clinical Skills and provide hands-on experience in key areas of Orthopedics and Sports Medicine. Each program is led by Seasoned Professionals and tailored to offer in-depth knowledge, procedural competence, and patient management skills.</p>
                                    </div>
                                    <div className="table-lists">
                                        <ul>
                                            <li>Arthroplasty Fellowship</li>
                                            <li>Sports Injury & Arthroscopy Fellowship</li>
                                            <li>Complex Trauma</li>
                                            <li>Pelvi-Acetabular Fractures Fellowship</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Facilities Available */}
            <section className="facilities py-4">
                <div className="container">
                    <div className="image-shape">
                        <div className="shape-1">
                            <img src={CourseBg1} alt="BgDots" />
                        </div>
                    </div>

                    <div className="sec-title mb-4">
                        <h1>Facilities Available</h1>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="facilities-block-one fadeInUp h-100">
                                <div className="facilities-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h4>e-Classroom</h4>
                                    </div>
                                    <div className="table-content">
                                        <p>At Ashwini Hospitals, we understand the importance of continuous learning and education. Our state-of-the-art e-classrooms are equipped with modern technology to facilitate interactive learning experiences. Whether it's for medical professionals seeking to enhance their skills or patients looking to understand their conditions better, our e-classrooms provide a dynamic learning environment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="facilities-block-one fadeInUp h-100">
                                <div className="facilities-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h4>e-Library</h4>
                                    </div>
                                    <div className="table-content">
                                        <p>Knowledge is key to delivering exceptional healthcare services. Our extensive e-library offers a wide range of resources, including medical journals, research papers, and educational materials. Accessible to both staff and patients, our e-library fosters continuous learning, research, and innovation within the healthcare community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="facilities-block-one wow fadeInUp h-100">
                                <div className="facilities-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h4>Hostel</h4>
                                    </div>
                                    <div className="table-content">
                                        <p>We understand that receiving medical treatment or attending training programs may require accommodation for patients and their families. Our comfortable and secure hostel facilities ensure a supportive environment for those needing to stay overnight or for an extended period. With amenities designed for convenience and comfort, our hostels aim to alleviate the stress of being away from home during challenging times.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="facilities-block-one wow fadeInUp h-100">
                                <div className="facilities-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h4>Transportation</h4>
                                    </div>
                                    <div className="table-content">
                                        <p>Ensuring seamless access to healthcare is a priority at Ashwini Hospitals. Our transportation services provide convenient and safe options for patients and staff to reach our facilities. From shuttle services for patients undergoing treatment to transportation arrangements for medical professionals attending conferences or workshops, we strive to make every journey to and from our hospitals as smooth as possible.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <div className="facilities-block-one wow fadeInUp h-100">
                                <div className="facilities-table h-100">
                                    <div className="shape">
                                        <img src={CourseBg} alt="CourseBackground" />
                                    </div>
                                    <div className="table-header">
                                        <h4>Canteen</h4>
                                    </div>
                                    <div className="table-content">
                                        <p>Good nutrition plays a vital role in the healing process and overall well-being. Our onsite canteen offers a diverse range of nutritious and delicious meals, catering to varying dietary preferences and requirements. Whether it's a quick snack between appointments or a wholesome meal for patients and their families, our canteen ensures that everyone has access to quality food options within our premises.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Academics;