import React, { useEffect } from "react";
import "./About.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import AboutBanner from "../../assets/about_banner.jpg";
import FoundersBg1 from "../../assets/shapes/hexagontop3D.png";
import FoundersBg2 from "../../assets/shapes/hexagondown3D.png";
import Dots1 from "../../assets/shapes/dots.png";
import Dots2 from "../../assets/shapes/tilt_dots.png";
import Image1 from "../../assets/maya_gantayet1.png";
import Image2 from "../../assets/subrat_jena1.png";
// import dataImg from "../../assets/AH Circle.png";
import { PiStethoscope } from "react-icons/pi";
import MVBackground from "../../assets/shapes/missionvision_bg.png";

function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />

            {/* About Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={AboutBanner} alt="AboutBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/">Home</Link></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* About Message */}
            <div className="card about-style-two">
                <div className="container card-body">
                    <div className="sec-title">
                        <h1>Welcome to Ashwini Group of Hospitals</h1>
                    </div>
                    <div className="about_message">
                        <p>Welcome to Ashwini Hospital, Cuttack's premier private medical center, commissioned in 2006 with a singular focus on delivering tertiary-level healthcare in specialized branches of Medicine and Surgery. Nestled in Sector 1, CDA project area, our strategic location ensures easy accessibility via major State roads and National Highways, making us a beacon of healthcare excellence in the region. Since our inception, we have carved a niche in critical patient care, excelling in Trauma, Neurology, Cardiology, Pulmonology, and addressing various complex medical issues.</p>

                        <p>Today, Ashwini Hospital stands as a healthcare powerhouse with 500 patient beds spread across three specialty hospitals and three out-reach clinics. Our commitment extends beyond treatment to education, offering a spectrum of training courses, including Diploma and B.Sc. programs in Nursing, Diploma in Critical Care Medicine, Masters in Emergency Medicine, and Superspecialty courses such as DrNB in Neurology, DrNB in Neurosurgery,DrNB in Plastic Surgery, and DrNB in Critical Care.</p>

                        <p>At Ashwini Group, we are more than just a healthcare institution; we are a community dedicated to fostering well-being. Our dedicated staff and star doctors collaborate to maintain the highest standards in quality, ethics, and stability. We invite you to be a part of this journey towards optimal health, where our promise to the community, patrons, well-wishers, and service partners remains unwavering.</p>
                    </div>
                </div>
            </div>

            {/* Founder's Message */}
            <div className="card about-style-three">
                <div className="sec-title mb-4">
                    <h1>Our Founder's</h1>
                </div>
                <div className="pattern-layer1">
                    <img src={FoundersBg1} alt="Background Symmetric" />
                </div>

                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-6">
                            <div className="card-body image_block_three">
                                <div className="image-box">
                                    <div className="image-shape d-none d-lg-block">
                                        <div className="shape-1">
                                            <img src={Dots1} alt="BgDots" />
                                        </div>
                                        <div className="shape-2">
                                            <img src={Dots2} alt="BgDots" />
                                        </div>
                                    </div>
                                    <figure className="image-1">
                                        <img src={Image2} alt="Dr.Subrat Jena Img" title="Dr.Subrat Jena" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card-body content_block_one">
                                <p className="card-text">The state of health care of a nation is directly reflected in its economic growth and stability. Private sector has always played an important role in improving and sustaining the standards of health care in India as well as in other nations. The part played by regional players like Ashwini Hospital Group in meeting the health care needs of Odisha and neighboring States has been much more than satisfactory. This sense of accomplishment encourages us to grow and meet the growing needs and expectations of the community.</p>
                                <div className="experience-box">
                                    <div className="icon-box">
                                        <PiStethoscope />
                                    </div>
                                    <h2>Dr. Subrat Kumar Jena</h2>
                                    <span className="designation">Chairman & Founder</span>
                                    <span className="exp">32 years of medical experience</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3 about-style-four">
                <div className="pattern-layer2">
                    <img src={FoundersBg2} alt="Background Symmetric" />
                </div>

                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-6">
                            <div className="card-body content_block_one">
                                <p className="card-text">
                                    Ashwini Hospital was stablished in 2006 in an easily approachable area just outside the busy city center at Cuttack. Very soon it became the hotspot for all types of emergencies. The hospital kept on scaling new heights, winning the trust of people and the medical fraternity who treated the unit as a referral center for all their patients with complex medical and surgical problems. The hospital was empaneled by major insurance providers and public as well as private institutions and industries. Constant upgradation of technology and training, continuous optimization of standards of care was rewarded by various accolades and accreditations.</p>
                                <div className="experience-box">
                                    <div className="icon-box">
                                        <PiStethoscope />
                                    </div>
                                    <h2>Dr. Maya Gantayet</h2>
                                    <span className="designation">Managing Director & Co-Founder</span>
                                    <span className="exp">32 years of medical experience</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card-body image_block_three">
                                <div className="image-box">
                                    <div className="image-shape d-none d-lg-block">
                                        <div className="shape-3">
                                            <img src={Dots1} alt="BgDots" />
                                        </div>
                                        <div className="shape-4">
                                            <img src={Dots2} alt="BgDots" />
                                        </div>
                                    </div>
                                    <figure className="image-2">
                                        <img src={Image1} alt="Dr.Maya Gantayet Img" title="Dr.Maya Gantayet" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mission & Vision */}
            <section className="last-section my-5">
                <div className="container">
                    {/* <div className="core-values">
                    <div className="sec-title mb-4">
                    <h1>Core Values</h1>
                   </div>
                     <div className="core-img">
                     <img src={dataImg} alt="" />
                     </div>
                    </div> */}
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2 d-flex flex-column">
                            <div className="last-block-one fadeInUp">
                                <div className="last-table">
                                    <div className="shape">
                                        <img src={MVBackground} alt="Mission_Vision" />
                                    </div>
                                    <div className="sec-title">
                                        <h1>Mission</h1>
                                    </div>
                                    <div className="text-box">
                                        <p>Ashwini Hospital would strive to establish a world class institute in Healthcare and stay in the forefront of medical technology and best practices thereby fulfilling the long felt need of true super specialty healthcare in the region.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2 d-flex flex-column">
                            <div className="last-block-one active-block fadeInUp">
                                <div className="h-100">
                                    <div className="last-table d-flex flex-column">
                                        <div className="shape">
                                            <img src={MVBackground} alt="Mission_Vision" />
                                        </div>
                                        <div className="sec-title">
                                            <h1>Vision</h1>
                                        </div>
                                        <div className="text-box">
                                            <p>Ashwini Hospital, the temple of healing is committed to provide ethical, reliable, high quality and cost effective health care services with care and compassion to ensure complete patient satisfaction.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;