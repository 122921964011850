import React, { useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import hospital from '../../assets/services icons/hospital.png';
import Patients from '../../assets/services icons/patient.png';
import Surgeries from '../../assets/services icons/surgeon.png';
import Heart from '../../assets/services icons/heart-s.png';
import Neuro from '../../assets/services icons/neuro.png';
import Joint from '../../assets/services icons/joint.png';
import Cleft from '../../assets/services icons/cleft-lip.png';
import Dialysis from '../../assets/services icons/dialysis.png';

const Stats = () => {
    const [countStarted, setCountStarted] = useState(false);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1, 
    });

    if (inView && !countStarted) {
        setCountStarted(true);
    }
    return (
        <div className="stats-container py-5"ref={ref}>
            <h2 className="text-center mb-5">Key Stats</h2>
            <div className="row text-center">
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={hospital} alt="Medical Excellence" />
                        <div className='stats-sec mt-2'>
                        <h5>{countStarted ? <CountUp end={18} duration={2} /> : 0}+</h5>
                            <p>Years of Medical Excellence</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Patients} alt="Patients treated" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={45000} duration={2} /> : 0}+</h5>
                            <p>Patients Treated Every Year</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Surgeries} alt="Successful Surgeries" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={40000} duration={2} /> : 0}+</h5>
                            <p>Successful Surgeries</p></div>

                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Heart} alt="Heart Surgeries" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={1000} duration={2} /> : 0}+</h5>
                            <p>Successful Heart Procedures</p></div>

                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Neuro} alt="Neuro Cases" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={18000} duration={2} /> : 0}+</h5>
                            <p>Neuro Cases</p></div>

                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Joint} alt="Joint Replacements" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={5000} duration={2} /> : 0}+</h5>
                            <p>Joint Replacements</p></div>

                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Cleft} alt="Cleft Surgeries" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={15000} duration={2} /> : 0}+</h5>
                            <p>Free Cleft Surgeries</p></div>

                    </div>
                </div>
                <div className="col-md-3 mb-4">
                    <div className='stats'>
                        <img src={Dialysis} alt="Dialysis" />
                        <div className='stats-sec mt-2'>
                            <h5>{countStarted ? <CountUp end={5000} duration={2} /> : 0}+</h5>
                            <p>Successful Dialysis</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stats;
