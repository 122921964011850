import React from "react";
import "./Navbar.css";
import HeaderLogo from "../../assets/headerlogo.png";
import NABH from "../../assets/NABH.png";
import SEMI from "../../assets/SEMI.png";
import NBE from "../../assets/NBE.png";
import ISCCM from "../../assets/ISCCM.png";
import SmileTrain from "../../assets/SmileTrain.png";
import AahNABH from "../../assets/aah-nabh.png";
import { useLocation } from "react-router-dom";

function Navbar() {
    const location = useLocation();

    const gradeLogos = () => {
        const path = location.pathname;
        if (path.includes('AshwiniHospitalCuttack')) {
            return [NABH, SEMI, NBE, ISCCM];
        } else if (path.includes('AshwiniTraumaCentre')) {
            return [NABH, SEMI, NBE, SmileTrain];
        } else if (path.includes('AdityaAshwiniHospital')) {
            return [AahNABH];
        } else {
            return [NABH, SEMI, NBE, ISCCM]; // Default
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light header-top fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={HeaderLogo} alt="Header Logo" title="Ashwini Group Of Hospitals" />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a href="/" className="nav-link">Home</a>
                            </li>

                            <li className="nav-item">
                                <a href="/About" className="nav-link">About Us</a>
                            </li>

                            <li className="nav-item dropdown">
                                <a href="/Departments" className="nav-link ">
                                    Departments
                                </a>
                                <ul className="dropdown-menu departments-dropdown">
                                    <div className="d-flex"> 
                                        <div className="p-1">
                                            <li><a className="dropdown-item" href="/Neurology">Neurology</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Neurosurgery">Neurosurgery</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Orthopedics">Orthopedics</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/PlasticSurgery">Plastic Surgery</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Pulmonary">Pulmonary & Respiratory Medicine</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/CriticalCare">Critical Care</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/EmergencyMedicine">Emergency Medicine Care</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Cardiology">Cardiology</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Cardiothoracic">Cardiothoracic Surgery</a></li>
                                            <hr />
                                        </div>
                                            
                                        <div className="p-1">
                                            <li><a className="dropdown-item" href="/Dermatology">Dermatology</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/HandSurgery">Hand & Micro Vascular Surgery</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Gynaecology">Obstetrics & Gynaecology</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Nephrology">Nephrology & Dialysis</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Urology">Urology</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/MaxilloFacialSurgery">Oral & Maxillo-Facial Surgery</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/GeneralSurgery">General Surgery & Laparoscopy</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Anesthesiology">Anesthesiology & Pain Management</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/SpineSurgery">Spine Surgery</a></li>
                                            <hr />
                                        </div>
                                        <div className="p-1">
                                            <li><a className="dropdown-item" href="/Dental">Dental</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Pediatric">Pediatrics</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Physiotherapy">Physiotherapy & Rehabilitation</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Psychology">Psychology & Psychotherapy</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Dietetics">Dietetics</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/Radiology">Radiology</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/LaboratoryServices">Laboratory Services</a></li>
                                            <hr />
                                            <li><a className="dropdown-item" href="/AlliedServices">Allied Services</a></li>
                                            <hr />
                                        </div>
                                    </div>



                                </ul>
                            </li>

                            <li className="nav-item">
                                <a href="/Academic" className="nav-link">Academics</a>
                            </li>
                            <li className="nav-item">
                                <a href="/Career" className="nav-link">Career</a>
                            </li>

                            <li className="nav-item">
                                <a href="/News&Media" className="nav-link">News & Media</a>
                            </li>

                            <li className="nav-item">
                                <a href="/Contact" className="nav-link">Contact</a>
                            </li>
                        </ul>

                        <div className="navbar-brand">
                            {gradeLogos().map((logo, index) => (
                                <img className="me-1 gradeLogos" key={index} src={logo} alt={`Logo${index}`} title="Medical Grades" />
                            ))}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
