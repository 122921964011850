import React from 'react'
import "./Events.css"
import Navbar from '../../Navbar/Navbar'
import Shape1 from '../../../assets/shapes/plus.png'
import Img1 from '../../../assets/gallery/g63.jpg'
import Img2 from '../../../assets/gallery/g59.jpg'
import Img3 from '../../../assets/gallery/g53.jpg'

export default function Event2() {
  return (
    <>
            <Navbar />
            <div className="container event-page ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h1 className="event-title">ARDS-Update Workshop</h1>
                            </div>
                            <p className='intro'>Ashwini Group of Hospitals is proud to announce the success of its first ARDS-Update Workshop held in Cuttack, organized by the esteemed Department of Critical Care. This landmark event brought together top critical care experts from across India, healthcare professionals, and participants eager to advance their knowledge in Acute Respiratory Distress Syndrome. Ashwini’s commitment to medical excellence and innovation in critical care was at the heart of this workshop, designed to elevate patient outcomes and foster a culture of continual learning.</p>
                            <div className='sec-one'>
                                <img src={Img1} alt="ARDS-Update Workshop" title='ARDS-Update Workshop' />
                                <div className='sec-one-details'>
                                    <p className='sub-intro'>Workshop Highlights</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>The workshop opened with dynamic lectures led by some of India’s most respected critical care specialists, covering the latest advancements in the field and key methodologies.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>These sessions underscored Ashwini Group of Hospitals' dedication to enhancing healthcare standards and patient-centered care in the region.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-two'>
                                <img src={Img2} alt="ARDS-Update Workshop" title='ARDS-Update Workshop' />
                                <div className='sec-two-details'>
                                    <p className='sub-intro'>Interactive Training & Knowledge Sharing</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>Participants were offered hands-on training, guided by experts, which allowed them to refine their skills and techniques.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>This interactive format encouraged collaboration and offered valuable insights into critical care best practices—one of many initiatives by Ashwini Group of Hospitals to drive professional growth and medical expertise.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-three d-flex'>
                                <img src={Img3} alt="ARDS-Update Workshop" title='ARDS-Update Workshop' />
                                <div className='sec-three-details'>
                                    <p className='sub-intro'>Celebrating Medical Excellence and Learning</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>Ashwini Group of Hospitals is honored to have hosted this impactful workshop, furthering its mission to lead in healthcare innovation and patient care excellence.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>The event served as a significant milestone for Ashwini, building a foundation for more educational events to benefit the healthcare community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
