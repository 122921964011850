import React from 'react'
import "./Events.css"
import Navbar from '../../Navbar/Navbar'
import Shape1 from '../../../assets/shapes/plus.png'
import Img1 from '../../../assets/gallery/g50.jpg'
import Img2 from '../../../assets/gallery/g51.jpg'
import Img3 from '../../../assets/gallery/g52.jpg'
import Ind from "../../../assets/shapes/india.png"

export default function Event3() {
  return (
    <>
    <Navbar />
    <div className="container event-page ">
        <div className="row">
            <div className="col-md-12">
                <div className="card mb-4">
                    <div className="card-body">
                        <h1 className="event-title">78th Independence Day celebration at Ashwini Trauma Centre</h1>
                    </div>
                    <p className='sub-intro text-center'><span>Happy</span> <span>Independence</span> <span>Day!</span></p>
                    <div className='sec-one'>
                        <img src={Img1} alt="78th Independence Day celebration at Ashwini Trauma Centre" title='78th Independence Day celebration at Ashwini Trauma Centre'/>
                        <div className='sec-one-details'>
                            <p className='sub-intro'>{ }</p>
                            <div className='sec-one-shape'>
                                <img src={Shape1} alt="shape" />
                                <p>United in our mission to heal, protect, and serve." This Independence Day, the Ashwini Group of Hospitals proudly celebrates the spirit of freedom with a renewed dedication to the health and well-being of our nation.</p>
                            </div>
                            <div className='sec-one-shape'>
                                <img src={Shape1} alt="shape" />
                                <p>Our journey is built on a commitment to providing compassionate, high-quality healthcare, empowering communities, and advancing medical excellence across the country.</p>
                            </div>
                        </div>
                    </div>
                    <div className='sec-two'>
                        <img src={Img2} alt="78th Independence Day celebration at Ashwini Trauma Centre" title='78th Independence Day celebration at Ashwini Trauma Centre'/>
                        <div className='sec-two-details'>
                            {/* <p className='sub-intro'>Interactive Training & Knowledge Sharing</p> */}
                            <div className='sec-one-shape'>
                                <img src={Shape1} alt="shape" />
                                <p>At Ashwini, we strive to bridge these gaps by making world-class healthcare accessible and affordable to all, regardless of socioeconomic status.</p>
                            </div>
                            <div className='sec-one-shape'>
                                <img src={Shape1} alt="shape" />
                                <p>We believe in empowering individuals with knowledge about their health, enabling them to make informed choices and lead healthier lives.</p>
                            </div>
                        </div>
                    </div>
                    <div className='sec-three d-flex'>
                        <img src={Img3} alt="78th Independence Day celebration at Ashwini Trauma Centre" title='78th Independence Day celebration at Ashwini Trauma Centre'/>
                        <div className='sec-three-details'>
                        <p className='sub-intro'>{ }</p>
                            <div className='sec-one-shape'>
                                <img src={Shape1} alt="shape" />
                                <p>Let us come together to celebrate not only our nation’s independence but also the health and resilience of its people.</p>
                            </div>
                            <div className='sec-one-shape'>
                                <img src={Shape1} alt="shape" />
                                <p>We pledge to uphold the highest standards of care, compassion, and integrity as we build a healthier and stronger India. Happy Independence Day! <img src={Ind} alt="Indian Flag" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
  )
}
