import React from 'react'
import "./Events.css"
import Navbar from '../../Navbar/Navbar'
import Shape1 from '../../../assets/shapes/plus.png'
import Img1 from '../../../assets/gallery/g71.jpg'
import Img2 from '../../../assets/gallery/g70.jpg'
import Img3 from '../../../assets/gallery/g72.jpg'

export default function Event1() {
    return (
        <>
            <Navbar />
            <div className="container event-page ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h1 className="event-title">Joint Reconstruction Workshop</h1>
                            </div>
                            <p className='intro'>Recently, the Ashwini Group of Hospitals’ Department of Cleft and Maxillofacial Surgery proudly organized a pre-conference workshop on TM Joint Reconstruction as part of the 3rd AOMSI Odisha State Chapter Conference. This workshop brought together maxillofacial surgeons, residents, and experts to explore and enhance surgical techniques in temporomandibular joint reconstruction. Here’s a glimpse into the highlights of this transformative day.</p>
                            <div className='sec-one'>
                                <img src={Img1} alt="Joint Reconstruction Workshop" title='Joint Reconstruction Workshop' />
                                <div className='sec-one-details'>
                                    <p className='sub-intro'>Workshop Highlights</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>The workshop began with a comprehensive lecture series covering the latest advancements and practical approaches in TM Joint Reconstruction.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>Our experienced faculty shared insights on new surgical procedures and treatment modalities, focusing on functional restoration and patient-centered outcomes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-two'>
                                <img src={Img2} alt="Joint Reconstruction Workshop" title='Joint Reconstruction Workshop' />
                                <div className='sec-two-details'>
                                    <p className='sub-intro'>Hands-On Training & Demonstrations</p>
                                    <div className='sec-one-shape'>
                                        {/* <img src={Shape1} alt="shape" /> */}
                                        <p>Participants engaged in hands-on sessions led by our expert surgeons, practicing advanced techniques and refining their skills with specialist guidance in a collaborative setting.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-three d-flex'>
                                <img src={Img3} alt="Joint Reconstruction Workshop" title='Joint Reconstruction Workshop'/>
                                <div className='sec-three-details'>
                                    <p className='sub-intro'>A Day of Learning and Collaboration</p>
                                    <div className='sec-one-shape'>
                                        {/* <img src={Shape1} alt="shape" /> */}
                                        <p>The event provided a unique platform for practitioners and trainees to connect, share knowledge, and discuss cases, marking it as a cornerstone event for professional growth in the field of maxillofacial surgery.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
