import React from 'react'
import "./Events.css"
import Navbar from '../../Navbar/Navbar'
import Shape1 from '../../../assets/shapes/plus.png'
import Img1 from '../../../assets/gallery/g39.jpg'
import Img2 from '../../../assets/gallery/g32.jpg'
import Img3 from '../../../assets/gallery/g33.jpg'

export default function Event5() {
  return (
    <>
            <Navbar />
            <div className="container event-page ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h1 className="event-title">Neuro-Radiology for Clinicians</h1>
                            </div>
                            <p className='intro'>Reflecting on an inspiring and transformative Neuro-Radiology for Clinicians Workshop! The Ashwini Group of Hospitals proudly hosted this impactful event, uniting some of the finest minds in neurology and radiology to share knowledge, explore innovations, and drive progress in patient care.</p>
                            <div className='sec-one'>
                                <img src={Img1} alt="Neuro-Radiology for Clinicians" title='Neuro-Radiology for Clinicians'/>
                                <div className='sec-one-details'>
                                    <p className='sub-intro'>{}</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>With groundbreaking insights and collaborative discussions, this workshop underscored our unwavering commitment to advancing healthcare and enhancing diagnostic precision in neuro-radiology.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>From case studies to the latest imaging techniques, every session was a testament to our dedication to clinical excellence and innovation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-two'>
                                <img src={Img2} alt="Neuro-Radiology for Clinicians" title='Neuro-Radiology for Clinicians' />
                                <div className='sec-two-details'>
                                <p>{}</p>
                                    <div className='sec-one-shape'>
                                        <p>Here's to the power of collaboration and a shared vision for a healthier tomorrow. Together, we’re making strides in healthcare that truly make a difference.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-three d-flex'>
                                <img src={Img3} alt="Neuro-Radiology for Clinicians" title='Neuro-Radiology for Clinicians'/>
                                <div className='sec-three-details'>
                                    <p>{}</p>
                                    <div className='sec-one-shape'>
                                        <p>Cheers to continued progress and impactful learning at Ashwini Group of Hospitals!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
