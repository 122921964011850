import React from "react";
import "./Events.css";
import Navbar from "../../Navbar/Navbar";
import Shape1 from "../../../assets/shapes/plus.png";
import Img1 from "../../../assets/gallery/g82.jpg";
import Img2 from "../../../assets/gallery/g84.jpg";
import Img3 from "../../../assets/gallery/g83.jpg";

export default function Event7() {
  return (
    <>
      <Navbar />
      <div className="container event-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="event-title">
                  Walkathon and Yoga Session for Stroke Awareness on World
                  Stroke Day
                </h1>
              </div>
              <p className="intro">
                On October 29, 2024, Ashwini Group of Hospitals marked World
                Stroke Day with a Walkathon and Yoga Session in Bhubaneswar.
                Over 300 participants gathered to raise awareness about stroke
                prevention, early detection, and the importance of stress-free
                living in reducing stroke risk.The Walkathon commenced at Aditya
                Ashwini Hospital, near BMC Kalyan Mandap, Chandrasekharpur, and
                concluded at Ashwini Wellness, Nalco Square. The event brought
                together dignitaries such as Former Odisha DGP Prakash Mishra as
                Chief Guest; Chairman of Ashwini Group of Hospitals, Dr. Subrat
                Jena; MD Dr. Maya Gantayet; Consultant Dr. Soumya Ranjan
                Pradhan; and Yoga Guru Akshaya Kumar Swain from Yoga India.
              </p>
              <div className="sec-one">
                <img
                  src={Img1}
                  alt="Stroke Day Walkathon"
                  title="Stroke Day Walkathon"
                />
                <div className="sec-one-details">
                  <p className="sub-intro">
                    The Importance of Stroke Awareness
                  </p>
                  <div className="sec-one-shape">
                    <img src={Shape1} alt="shape" />
                    <p>
                      Stroke remains a leading cause of disability and death
                      globally, highlighting the need for awareness about
                      prevention and immediate response.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <img src={Shape1} alt="shape" />
                    <p>
                      Ashwini Group of Hospitals advocates for educating the
                      public on recognizing stroke symptoms, taking prompt
                      action, and adopting healthy lifestyle practices to reduce
                      risk factors.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <img src={Shape1} alt="shape" />
                    <p>
                      Dr. Maya Gantayet encouraged participants to be
                      #GreaterThanStroke, emphasizing the importance of
                      preventive care and staying informed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-two">
                <img
                  src={Img2}
                  alt="Stroke Day Walkathon"
                  title="Stroke Day Walkathon"
                />
                <div className="sec-two-details">
                  <p className="sub-intro">
                  Yoga for Stress Management and Stroke Prevention
                  </p>
                  <div className="sec-one-shape">
                    <img src={Shape1} alt="shape" />
                    <p>
                    Following the Walkathon, Yoga Guru Akshaya Kumar Swain led a session on stress-relieving yoga practices, showing how regular yoga can help prevent stroke by promoting relaxation, reducing blood pressure, and supporting mental well-being.
                    </p>
                  </div>
                  <div className="sec-one-shape">
                    <img src={Shape1} alt="shape" />
                    <p>
                    Consultant Dr. Soumya Ranjan Pradhan discussed practical ways that lifestyle adjustments, including diet, exercise, and yoga, play an essential role in lowering stroke risk.
                    </p>
                  </div>
                </div>
              </div>
              <div className="sec-three d-flex">
                <img
                  src={Img3}
                  alt="Stroke Day Walkathon"
                  title="Stroke Day Walkathon"
                />
                <div className="sec-three-details">
                  <p className="sub-intro">
                    {}
                  </p>
                  <div className="sec-one-shape">
                  
                    <p>
                    The event was coordinated by Director of Finance, Suma Devi Dash; Director of Strategy, Jay Dash; Deputy Superintendent of Aditya Ashwini Hospital, Dr. Manas Ranjan Swain; and Quality Head, Sanjay Panda, who worked to ensure the event’s success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
