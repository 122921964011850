import React, { useEffect, useState } from "react";
import "./News&Media.css"
import Navbar from "../Navbar/Navbar";
import TabItems from "./TabItems";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function NewsMedia() {
    const [items, setItems] = useState(TabItems.filter(item => item.category === 'gallery'));
    const [currentMedia, setCurrentMedia] = useState(null);

    const handletab = (data) => {
        const updatedItems = TabItems.filter((currentElement) => currentElement.category === data)
        setItems(updatedItems);
    }

    const handleMediaClick = (media, type, event) => {
        if (event) {
            event.preventDefault();
        }

        // Pause all videos in the background
        const videoElements = document.querySelectorAll('.gallery-items video');
        videoElements.forEach(video => video.pause());

        // Update current media state
        setCurrentMedia({ media, type });

        /// Show the modal
        const modalElement = document.getElementById('mediaModal');
        const newModal = new window.bootstrap.Modal(modalElement);
        newModal.show();
    };

    useEffect(() => {
        const modalElement = document.getElementById('mediaModal');

        const handleModalHidden = () => {
            if (currentMedia && currentMedia.type === 'video') {
                const videoElement = modalElement.querySelector('video');
                if (videoElement) {
                    videoElement.pause();
                    videoElement.currentTime = 0; // Reset the video to the beginning
                }
            }
            setCurrentMedia(null); // Reset currentMedia when modal is hidden
        };

        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup the event listener on unmount
        return () => {
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [currentMedia]);

    return (
        <>
            <Navbar />

            <div className="container gallery-tabs">
                <div className="gallery-box d-flex justify-content-around">
                    <button className="btn btn-warning" onClick={() => handletab('gallery')}>Gallery</button>
                    <button className="btn btn-warning" onClick={() => handletab('newsarticles')}>News & Articles</button>
                    <button className="btn btn-warning" onClick={() => handletab('testimonials')}>Testimonials</button>
                    <button className="btn btn-warning" onClick={() => handletab('blogs')}>Blogs</button>
                </div>
            </div>

            <div className="container my-5">
                <div className="row">
                    {items.map((item, index) => {
                        const { media, alt, type, text, link ,category } = item;

                        if (type === 'image' || type === 'video') {
                            return (
                                <div className="col-sm-12 col-md-6 col-lg-3 mb-4" key={index} onClick={(e) => category === 'newsarticles' ? handleMediaClick(media, type, e) : null}>
                                    {/*add this above for modal onClick={(e) => handleMediaClick(media, type, e)} */}
                                    <div className="card gallery-items">
                                        {type === 'image' ? (
                                            <>
                                                {/* <img src={media} alt={alt} />
                                                <div className="hover-text">{text}</div> */}
                                                <div>
                                                <a href={link}>
                                                    <LazyLoadImage
                                                        src={media}
                                                        alt={alt}
                                                        effect="blur"
                                                        className="img-fluid"
                                                    />
                                                </a>
                                                <p className="gallery-text">{text}</p>
                                                </div>
                                                 {/* <a href={link}>
                                                    <LazyLoadImage
                                                        src={media}
                                                        alt={alt}
                                                        effect="blur"
                                                        className="img-fluid"
                                                    />
                                                </a>
                                                <div className="hover-text">{text}</div> */}
                                            </>
                                        ) : (
                                            <video controls>
                                                <source src={media} type="video/mp4" />
                                            </video>
                                            // <iframe src={link}></iframe>
                                        )}
                                    </div>
                                </div>
                            )
                        } else if (type === "blogs") {
                            return (
                                <div className="col-sm-12 col-md-6 col-lg-3 mb-4" key={index}>
                                    <div className="card gallery-item  d-flex justify-content-center align-items-center">
                                        <a href={link}>
                                            <img src={media} alt={alt} />
                                        </a>
                                    </div>
                                </div>
                            )
                        }

                        return null;
                    })}
                </div>
            </div>

            {/* Modal for displaying the image */}
            <div className="modal fade" id="mediaModal" tabIndex="-1" aria-labelledby="mediaModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="mediaModalLabel">Media Preview</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body d-flex justify-content-center">
                            {currentMedia && currentMedia.type === 'image' && <img src={currentMedia.media} alt="Selected" className="img-fluid" />}
                            {currentMedia && currentMedia.type === 'video' && (
                                <video controls autoPlay className="w-100">
                                    <source src={currentMedia.media} type="video/mp4" />
                                </video>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewsMedia;