import React from 'react'
import "./Events.css"
import Navbar from '../../Navbar/Navbar'
import Shape1 from '../../../assets/shapes/plus.png'
import Img1 from '../../../assets/gallery/g49.jpg'

export default function Event4() {
  return (
    <>
            <Navbar />
            <div className="container event-page ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h1 className="event-title">Doctor's Day</h1>
                            </div>
                            <p className='intro'>Reflecting on a truly wonderful National Doctor's Day celebration at Ashwini Hospitals! This special day was dedicated to honoring our incredible team of doctors, who selflessly give their expertise, time, and compassion to improve the lives of countless individuals in our community.
                            </p>
                            <div className='sec-one'>
                                <img src={Img1} alt="Doctor's Day" title="Doctor's Day"/>
                                <div className='sec-one-details'>
                                    <p>{ }</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>With heartfelt gratitude, we thank each of our doctors for their unwavering commitment to excellence in healthcare. Their dedication and relentless efforts ensure that our patients receive the highest standard of care, embodying the very spirit of service and healing.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>Here's to our doctors, the pillars of Ashwini Hospitals, whose resilience and dedication inspire us every day. Thank you for all that you do! Happy National Doctor's Day!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
