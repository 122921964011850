import React from 'react'
import "./Events.css"
import Navbar from '../../Navbar/Navbar'
import Shape1 from '../../../assets/shapes/plus.png'
import Img1 from '../../../assets/gallery/g44.jpg'
import Img2 from '../../../assets/gallery/g45.jpg'
import Img3 from '../../../assets/gallery/g42.jpg'


export default function Event6() {
  return (
    <>
            <Navbar />
            <div className="container event-page ">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h1 className="event-title">Neuro-Immunology Workshop</h1>
                            </div>
                        
                            <div className='sec-one'>
                                <img src={Img1} alt="Neuro-Immunology Workshop" title='Neuro-Immunology Workshop'/>
                                <div className='sec-one-details'>
                                    <p className='sub-intro'>{}</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>Reflecting on an incredible gathering of minds! The Ashwini Group of Hospitals was honored to host a transformative Neuro-Immunology Workshop, bringing together leading experts in neurology and immunology.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>This event highlighted cutting-edge insights, collaborative discussions, and a shared commitment to advancing healthcare for patients facing complex neurological and immunological conditions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-two'>
                                <img src={Img2} alt="Neuro-Immunology Workshop" title='Neuro-Immunology Workshop'/>
                                <div className='sec-two-details'>
                                    <p className='sub-intro'>{}</p>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>From breakthrough research to new approaches in treatment, the workshop underscored our dedication to fostering innovation and teamwork in healthcare.</p>
                                    </div>
                                    <div className='sec-one-shape'>
                                        <img src={Shape1} alt="shape" />
                                        <p>We’re energized by the progress made and look forward to further driving excellence and collaboration in patient care.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='sec-three d-flex'>
                                <img src={Img3} alt="Neuro-Immunology Workshop" title='Neuro-Immunology Workshop'/>
                                <div className='sec-three-details'>
                                    <p className='sub-intro'>{}</p>
                                    <div className='sec-one-shape'>
                                        <p>Stay tuned as we continue to shape the future of healthcare together at Ashwini Group of Hospitals!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
