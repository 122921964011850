import React from "react";
import Navbar from "../../Navbar/Navbar";
import "./Blog.css";
import img1 from "../../../assets/Blog Assets/Blog1.jpg";
import img2 from "../../../assets/Blog Assets/npwt1.jpg";
import img3 from "../../../assets/doctors/subrat_jena.png";
import img4 from "../../../assets/Blog Assets/ot1.jpg";

export default function Blog1() {
  return (
    <>
      <Navbar />
      <div className="container blog-page ">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <h1 className="blog-title">
                  The Role of Negative Pressure Wound Therapy (NPWT) in Treating
                  Complex Wounds
                </h1>
                <img
                  src={img1}
                  alt="Negative Pressure Wound Therapy"
                  className="img-fluid blog-image mb-4"
                />
                <p className="blog-font-size">
                  Negative Pressure Wound Therapy (NPWT) has become a pivotal
                  technique in managing complex wounds, particularly in trauma
                  cases, general surgical wounds, and diabetic foot ulcers. This
                  innovative approach has been supported by numerous
                  prospective, multi-centered randomized controlled trials
                  demonstrating its efficacy, especially for diabetic foot
                  wounds.
                </p>

                <h2 className="blog-sub-heading">Mechanism of Action</h2>
                <p>NPWT works through several key mechanisms:</p>
                <ul className="mech-action blog">
                  <li>
                    <strong>Increased Blood Flow:</strong> By creating a vacuum
                    environment, NPWT enhances perfusion to the wound area,
                    promoting healing.
                  </li>
                  <li>
                    <strong>Macro Deformation:</strong> The application of
                    negative pressure induces a macro deformation effect, which
                    aids in wound contraction.
                  </li>
                  <li>
                    <strong>Granulation and Angiogenesis:</strong> NPWT
                    stimulates the formation of granulation tissue and new blood
                    vessels, essential for wound healing.
                  </li>
                  <li>
                    <strong>
                      Reduction of Edema and Bacterial Colonization:
                    </strong>{" "}
                    The therapy effectively decreases fluid accumulation and
                    bacterial load, minimizing the risk of infection.
                  </li>
                </ul>
                <img
                  src={img4}
                  alt="ot"
                  className="img-fluid blog-image mb-4"
                />
                <p>
                  However, the success of NPWT hinges on thorough patient
                  assessment. Factors such as wound ischemia, sensory
                  neuropathy, deep infections (like osteomyelitis), and septic
                  arthritis can compromise treatment outcomes. It is crucial
                  that adequate debridement is performed to eliminate all
                  devitalized, necrotic, and infected tissue before initiating
                  NPWT.
                </p>

                <h2 className="blog-sub-heading">
                  Monitoring Post-NPWT Application
                </h2>
                <p>
                  After NPWT is applied, meticulous monitoring is essential to
                  ensure optimal healing. Key parameters to observe include:
                </p>
                <ul className="list-item-ul">
                  <li>
                    <strong>Wound Condition:</strong> Regular assessment of the
                    wound for any signs of infection or deterioration.
                  </li>
                  <li>
                    <strong>Antibiotic Support:</strong> Ensuring appropriate
                    antibiotic therapy is in place to combat any potential
                    infection.
                  </li>
                  <li>
                    <strong>Glycemic Control:</strong> Maintaining blood sugar
                    levels, especially in diabetic patients, is vital for wound
                    healing.
                  </li>
                  <li>
                    <strong>Hemoglobin Levels:</strong> Monitoring hemoglobin
                    (Hb%) levels to assess the patient's overall oxygen-carrying
                    capacity.
                  </li>
                  <li>
                    <strong>Renal Values and Protein Levels:</strong> Keeping
                    track of renal function and protein levels (albumin and
                    pre-albumin) is crucial for nutritional status and healing.
                  </li>
                </ul>

                <h2 className="blog-sub-heading">
                  Device Application and Management
                </h2>
                <p className="blog-font-size">
                  The NPWT device is applied to the wound in a standardized
                  manner. A negative pressure of 125 mmHg is commonly set,
                  typically in continuous mode. Dressings are usually changed
                  every three days, although this may vary based on the specific
                  wound and dressing type.
                </p>
                <p className="blog-font-size">
                  Once the wound bed is adequately filled with granulation
                  tissue and has been properly prepared, NPWT can be
                  discontinued. At this stage, the wound can close either by
                  secondary intention or be covered with a split-thickness skin
                  graft (STSG) once cultures confirm the absence of
                  microorganisms.
                </p>
                <h3 className="blog-sub-heading">Case Study</h3>
                <div className="case-study-section">
                  <img
                    src={img2}
                    alt="Patient Rahul Jena's Wound"
                    className="case-study-image img-fluid"
                  />
                  <div className="case-study-text">
                    <p>
                      A recent case highlights the effectiveness of NPWT in
                      treating complex wounds. A 30-year-old male, presented
                      with a non-healing wound on the right medial aspect of his
                      leg, which had persisted for six months following a road
                      traffic accident. He was a known case of Type 1 Diabetes
                      Mellitus and had previously received treatment elsewhere.
                    </p>
                    <p>
                      Upon examination, the patient exhibited stable vital signs
                      and a significant wound measuring 10x5 cm, characterized
                      by exposed tendons, deep cavities with pus, and a foul
                      odor. Following necessary investigations and a
                      consultation with Dr. S. K. Jena from the <span><a href="/PlasticSurgery" className="text-decoration-none">Plastic Surgery </a></span>
                      Department, a bedside debridement was performed. However,
                      due to the unhealthy state of the wound, NPWT was
                      initiated. The NPWT application (VAC version 2) was
                      carried out on December 29, 2023, and continued until
                      January 3, 2024. After the removal of NPWT, the wound
                      showed satisfactory drainage, with a significantly
                      healthier bed enriched with granulation tissue.
                      Subsequently, a split-skin graft was harvested and meshed,
                      leading to complete healing of the defect.
                    </p>
                  </div>
                </div>

                <h2 className="blog-sub-heading">Acknowledgments</h2>
                <div className="acknowledgment-section">
                  <div className="doc-link">
                    <a href="/Doctors/dr.subratkumarjena">
                      <img src={img3} alt="Dr. Subrat Kumar Jena" />
                      <p>Dr. Subrat Kumar Jena</p>
                    </a>
                  </div>
                  <p>
                    A heartfelt thanks to{" "}
                    <span>
                      <a
                        href="/Doctors/dr.subratkumarjena"
                        className="text-decoration-none"
                      >
                        Dr. Subrat Kumar Jena
                      </a>
                    </span>
                    , the dressing team, our nursing in-charge, and all the
                    dedicated staff whose commitment and skill were vital in the
                    successful management of this case. Their expertise and
                    compassionate care ensured a positive outcome, reflecting
                    the highest standards of patient care. Each team member
                    played an essential role, demonstrating both precision and
                    empathy throughout the treatment process. This success is a
                    testament to their unwavering dedication to patient
                    well-being. We deeply appreciate their hard work and
                    tireless efforts in making this recovery journey possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
