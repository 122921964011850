import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import NeurologyBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/maya_gantayet.png";
import Img2 from "../../assets/doctors/geeta_mohanty.jpg";
import Img3 from "../../assets/doctors/Soumya_Pradhan.png";
import Img4 from "../../assets/doctors/Binay_ku_Jaiswal.jpg";
import Img5 from "../../assets/doctors/manoranjan.jpg";
import G1 from "../../assets/department photos/neuro1.png";
import G2 from "../../assets/department photos/neuro2.png";
import G3 from "../../assets/department photos/neuro3.png";
import G4 from "../../assets/department photos/neuro4.png";
import G5 from "../../assets/department photos/neuro5.png";
import G6 from "../../assets/department photos/neuro6.png";
import G7 from "../../assets/department photos/neuro7.png";
import G8 from "../../assets/department photos/neuro8.png";
import G9 from "../../assets/department photos/neuro9.png";
import G10 from "../../assets/department photos/neuro10.jpg";
import G11 from "../../assets/department photos/neuro11.jpg";
import G12 from "../../assets/department photos/neuro12.jpg";
import G13 from "../../assets/department photos/neuro13.jpg";
import G14 from "../../assets/department photos/neuro14.jpg";
import G15 from "../../assets/department photos/neuro15.jpg";
import G16 from "../../assets/department photos/neuro16.jpg";
import G17 from "../../assets/department photos/neuro17.jpg";

function Neurology() {
    const [openIndex, setOpenIndex] = useState(null);
    const [currentImage, setCurrentImage] = useState('');
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Maya Gantayet",
            qualification: "DM(Neurology), M.Sc(Neuro-intervention), Faculty DrNB Neurology",
            location: "Ashwini Hospital, Ashwini Wellness"
        },
        {
            image: Img2,
            name: "Dr. Geeta Mohanty",
            qualification: "DM(Neurology), Faculty DrNB Neurology",
            location: "Ashwini Hospital"
        },
        {
            image: Img5,
            name: "Dr. Manoranjan Acharya",
            qualification: "DM(Neurology), Faculty DrNB Neurology",
            location: "Ashwini Hospital"
        },
        {
            image: Img3,
            name: "Dr. Soumya Ranjan Pradhan",
            qualification: "MBBS, MD(Medicine), DM(Neurology), DrNB Faculty",
            location: "Ashwini Hospital, Aditya Ashwini Hospital"
        },
        {
            image: Img4,
            name: "Dr. Binay Kumar Jaiswal",
            qualification: "MD(Medicine), DM(Neurology)",
            location: "Ashwini Hospital"
        },
        
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
        { image: G4 },
        { image: G5 },
        { image: G6 },
        { image: G7 },
        { image: G8 },
        { image: G9 },
        { image: G10 },
        { image: G11 },
        { image: G12 },
        { image: G13 },
        { image: G14 },
        { image: G15 },
        { image: G16 },
        { image: G17 },
    ];

    // const opdTimings = [
    //     {
    //         doctor: 'Dr. Maya Gantayet',
    //         schedule: [
    //             { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Hospital' },
    //             { day: 'TUE', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Hospital' },
    //             { day: 'THU', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Hospital' },
    //             { day: 'FRI', time: '06:00 PM - 08:00 PM', centre: 'Ashwini Wellness' }
    //         ]
    //     },
    //     {
    //         doctor: 'Dr. Soumya Ranjan Pradhan',
    //         schedule: [
    //             { day: 'MON', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
    //             { day: 'WED', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
    //             { day: 'FRI', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
    //             { day: 'SAT', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' }
    //         ]
    //     }
    // ]
    const opdTimings = [
        {
            type: 'General Neurology',
            schedule: [
                { day: 'Monday', time: '10:00 AM - 06:00 PM', doctors: ['Dr. Maya Gantayet', 'Dr. Soumya Ranjan Pradhan', 'Dr. Binay Kumar Jaiswal'] },
                { day: 'Tuesday', time: '10:00 AM - 06:00 PM', doctors: ['Dr. Maya Gantayet', 'Dr. Soumya Ranjan Pradhan', 'Dr. Binay Kumar Jaiswal', 'Dr. Geeta Mohanty'] },
                { day: 'Wednesday', time: '02:00 PM - 06:00 PM', doctors: ['Dr. Binay Kumar Jaiswal'] },
                { day: 'Friday', time: '02:00 PM - 06:00 PM', doctors: ['Dr. Binay Kumar Jaiswal'] },
                { day: 'Saturday', time: '02:00 PM - 06:00 PM', doctors: ['Dr. Binay Kumar Jaiswal'] },
            ]
        },
        {
            type: 'Stroke and Follow-up',
            schedule: [
                { day: 'Thursday', time: '10:00 AM - 04:00 PM', doctors: ['Dr. Maya Gantayet', 'Dr. Binay Kumar Jaiswal'] }
            ]
        },
        {
            type: 'Neuroimmunology',
            schedule: [
                { day: 'Thursday', time: '04:00 PM - 06:00 PM', doctors: ['Dr. Soumya Ranjan Pradhan'] }
            ]
        },
        {
            type: 'Epilepsy',
            schedule: [
                { day: 'Friday', time: '10:00 AM - 01:00 PM', doctors: ['Dr. Maya Gantayet'] },
                { day: 'Friday', time: '02:00 PM - 06:00 PM', doctors: ['Dr. Binay Kumar Jaiswal'] }
            ]
        }
    ];
    return (
        <>
            <Navbar />

            {/* Neurology Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={NeurologyBanner} alt="Services Banner" title="Services Banner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Neurology</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Neurology Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name" href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                    <div className="card h-100 doctor_img">
                                        <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                        <div className="doc-text">
                                            <h3>{slide.name}</h3>
                                            <p>{slide.qualification}</p>
                                            <span className="text-dark">{slide.location}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    <div className="accordion" id="opdTimingsAccordion">
                                        {opdTimings.map((opd, index) => (
                                            <div className="accordion-item" key={`opd-${index}`}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button
                                                        className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => toggleAccordion(index)}
                                                        aria-expanded={openIndex === index ? "true" : "false"}
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        {opd.type}
                                                        <span className="toggle-text">
                                                            {openIndex === index ? "Hide" : "Show"}
                                                        </span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-bs-parent="#opdTimingsAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <table className="table table-hover table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">DAYS</th>
                                                                    <th scope="col">TIMINGS</th>
                                                                    <th scope="col">DOCTORS</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {opd.schedule.map((schedule, idx) => (
                                                                    <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                        <th scope="row">{schedule.day}</th>
                                                                        <td>{schedule.time}</td>
                                                                        <td>
                                                                            {schedule.doctors.map((doctor, docIdx) => (
                                                                                <p key={docIdx}>{doctor}</p>
                                                                            ))}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Neurology Gallery" title="Neurology Department" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Neurology Gallery" title="Neurology Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Ashwini hospital is regarded as one of the leading hospital for neurology and neurosurgery in Eastern
                                            part of India. Our Department Of Neurosciences is committed to provide high quality, secondary and
                                            tertiary neuro services to patients in the region, as well as to all those who have recourse to our
                                            facility. Well accompanied by Dept of Neuroradiology, Neurointervention, Neuro anesthesia and Critical
                                            care we are able to provide 24X7 services with treatment outcomes are comparable to global statistics.
                                            We are well trained and equipped for the treatments of :-</p>
                                        <ul className="ul_list">
                                            <li>Headache and migraine</li>
                                            <li>Cerebrovascular and movement disorders</li>
                                            <li>Multiple sclerosis</li>
                                            <li>Neuropathy</li>
                                            <li>Management of neurobehavioral and memory disorders</li>
                                            <li>Alzheimer's and Parkinson's disease</li>
                                            <li>Neuromuscular diseases</li>
                                            <li>Seizure disorders</li>
                                            <li>Bell's palsy</li>
                                            <li>Cerebral palsy and related neurological disorders</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Facilities Available */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Facilities Available</h1>
                                    </div>
                                    <div className="card-text mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <ul className="text-nowrap">
                                                    <li>EEG (Electro Encephalogram)</li>
                                                    <li>NCS (Nerve Conduction Study)</li>
                                                    <li>Evoked Potential</li>
                                                    <li>EMG</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <ul>
                                                    <li>Transcranial Doppler</li>
                                                    <li>CT Scan</li>
                                                    <li>MRI (1.5T)</li>
                                                    <li>CT Perfusion</li>
                                                    <li>Functional MRI</li>
                                                    <li>DSA</li>
                                                    <li>Neuroquant</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4">
                                                <ul>
                                                    <li>Mechanical Thrombectomy</li>
                                                    <li>Neuroimmunology</li>
                                                    <li>Dedicated Epilepsy</li>
                                                    <li>Dedicated Stroke ICU</li>
                                                    <li>Stroke Team 24*7</li>
                                                    <li>Stroke Clinic</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Neurology;